import React from 'react'
import Main from '../layout/Main'
import { Card, Col, Container, Nav, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import BetSlip from '../components/tabs/betting/BetSlip'
import Odd from '../components/tabs/betting/Odd'
import Match from '../components/tabs/betting/Match'
import League from '../components/tabs/betting/League'
import Prediction from '../components/tabs/betting/Prediction'
import PredictionCategory from '../components/tabs/betting/PredictionCategory'
import NItem from './pages_components/NItem'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Betting(__props) {
  const location = useLocation()
  // eslint-disable-next-line no-undef
  const tab = new URLSearchParams(location.search)?.get('tab')

  return (
    <>
      <Main>
        <Container fluid>
          <Row>
            <Col xs="12" sm="12" md="4" lg="3">
              <Card>
                <Card.Header className="mb-0 pb-0">
                  <h5>Bettings</h5>
                </Card.Header>
                <Card.Footer className="mt-0 pt-0 mx-1 px-1">
                  <Nav variant="pills" className="s-grid">
                    <NItem
                      to="../betting?tab=betslip"
                      active={!tab || tab === 'betslip'}
                      label="Bet Slip"
                      icon="fas fa-book"
                    />
                    <NItem to="../betting?tab=odd" active={tab === 'odd'} label="Odd" icon="fas fa-download" />
                    <NItem to="../betting?tab=match" active={tab === 'match'} label="Match" icon="fas fa-download" />
                    <NItem to="../betting?tab=league" active={tab === 'league'} label="League" icon="fas fa-download" />
                    <NItem
                      to="../betting?tab=prediction"
                      active={tab === 'prediction'}
                      label="Prediction"
                      icon="fas fa-download"
                    />
                    <NItem
                      to="../betting?tab=predictioncategory"
                      active={tab === 'predictioncategory'}
                      label="Prediction Category"
                      icon="fas fa-download"
                    />
                  </Nav>
                </Card.Footer>
              </Card>
            </Col>
            <Col xs="12" sm="12" md="8" lg="9" className="mt-3">
              <Card>
                <Card.Body>
                  {tab === 'odd' ? (
                    <Odd />
                  ) : tab === 'match' ? (
                    <Match />
                  ) : tab === 'league' ? (
                    <League />
                  ) : tab === 'prediction' ? (
                    <Prediction />
                  ) : tab === 'predictioncategory' ? (
                    <PredictionCategory />
                  ) : (
                    <BetSlip />
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Main>
    </>
  )
}
export default Betting
