/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react'
import { Col, Form, InputGroup, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { CREATE_REFERRAL_LEVEL } from '../../../../scripts/config/RestEndpoints'
import Spinner from '../../../paginating/Spinner'
import fetcher from '../../../../scripts/SharedFetcher'

function ReferralLevelForm(props) {
  const dataIdRef = useRef('')

  const [isUpdate, setIsUpdate] = useState(false)

  const [submitting, setSubmitting] = useState(false)

  const [name, setName] = useState('')
  const [planPercentage, setPlanPercentage] = useState('')
  const [tradePercentage, setTradePercentage] = useState('')
  const [code, setCode] = useState('')

  useEffect(() => {
    const data = props.data
    if (data) {
      dataIdRef.current = data._id

      setName(data.name)
      setPlanPercentage(data.planPercentage)
      setTradePercentage(data.tradePercentage)
      setCode(data.code)

      setIsUpdate(true)
    }
  }, [props.data])

  async function createReferralLevel(e) {
    setSubmitting(true)
    e.preventDefault()
    const gdFetchOption = {
      url: CREATE_REFERRAL_LEVEL,
      data: {
        name,
        planPercentage,
        tradePercentage,
        code,
      },
    }
    let data
    try {
      data = await fetcher.fetch(gdFetchOption)
    } catch (er) {
      toast.error(er.message)
    }
    if (data) {
      if (!data.data.status) {
        toast.error(data.data.message)
      } else {
        props.setData && props.setData(data.data.generated)
        props.setReload && props.setReload()
        toast.success(data.data.message)
      }
    }
    setSubmitting(false)
  }

  async function updateReferralLevel(e) {
    setSubmitting(true)
    e.preventDefault()
    const gdFetchOption = {
      url: CREATE_REFERRAL_LEVEL,
      method: 'PATCH',
      data: {
        id: dataIdRef.current,
        name,
        planPercentage,
        tradePercentage,
        code,
      },
    }
    let data
    try {
      data = await fetcher.fetch(gdFetchOption)
    } catch (er) {
      toast.error(er.message)
    }
    if (data) {
      if (!data.data.status) {
        toast.error(data.data.message)
      } else {
        props.setData && props.setData(data.data.generated)
        props.setReload && props.setReload()
        toast.success(data.data.message)
      }
    }
    setSubmitting(false)
  }

  return (
    <Form onSubmit={(e) => (isUpdate ? updateReferralLevel(e) : createReferralLevel(e))}>
      <Row>
        <Col xs="12" sm="12" md="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Name</InputGroup.Text>
            <Form.Control
              required={true}
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Plan Percentage</InputGroup.Text>
            <Form.Control
              required={true}
              type="number"
              step={'0.1'}
              value={planPercentage}
              onChange={(e) => setPlanPercentage(e.target.value)}
            ></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Trade Percentage</InputGroup.Text>
            <Form.Control
              required={true}
              type="number"
              step={'0.1'}
              value={tradePercentage}
              onChange={(e) => setTradePercentage(e.target.value)}
            ></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Code</InputGroup.Text>
            <Form.Control
              required={true}
              type="number"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            ></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" className="p-1">
          <Spinner
            loading={submitting}
            loadingText={`${isUpdate ? 'Updating referral level' : 'Creating referral level'}`}
          >
            <Form.Control
              size="md"
              type="submit"
              value={`${isUpdate ? 'Update' : 'Create'}`}
              className="fw-bold utilityLink"
            ></Form.Control>
          </Spinner>
        </Col>
      </Row>
    </Form>
  )
}
export default ReferralLevelForm
