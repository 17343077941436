/* eslint-disable no-sparse-arrays */
const dashboardRoutes = [
  {
    path: '/home',
    name: 'Home',
    icon: 'fas fa-home',
  },
  {
    path: '/user',
    name: 'User',
    icon: 'fas fa-user',
  },
  {
    path: '/network',
    name: 'Network',
    icon: 'fas fa-chart-line',
  },
  {
    path: '/token',
    name: 'Token',
    icon: 'fas fa-bitcoin-sign',
  },
  {
    path: '/package',
    name: 'Package',
    icon: 'fas fa-box-open',
  },
  ,
  {
    path: '/referral-level',
    name: 'ReferralLevel',
    icon: 'fas fa-users',
  },
  {
    path: '/transaction',
    name: 'Transaction',
    icon: 'fas fa-wallet',
  },
  {
    path: '/betting',
    name: 'Betting',
    icon: 'fas fa-exchange-alt',
  },
  {
    path: '/system-revenue',
    name: 'Revenue',
    icon: 'fas fa-bank',
  },
  {
    path: '/option',
    name: 'Option',
    icon: 'fas fa-gears',
  },
  {
    path: '/setting',
    name: 'Setting',
    icon: 'fas fa-cog',
  },
  {
    path: '/git',
    name: 'Git',
    icon: 'fas fa-gears',
  },
  {
    path: '/migration',
    name: 'Migration',
    icon: 'fas fa-exchange',
  },
]

export default dashboardRoutes
