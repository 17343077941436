import React, { useState, useEffect, useRef } from 'react'
import { Col, Form, InputGroup, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { CREATE_MATCH } from '../../../../scripts/config/RestEndpoints'
import Spinner from '../../../paginating/Spinner'
import fetcher from '../../../../scripts/SharedFetcher'

function MatchForm(props) {
  const dataIdRef = useRef('')

  const [isUpdate, setIsUpdate] = useState(false)

  const [submitting, setSubmitting] = useState(false)

  const [homeTeam, setHomeTeam] = useState('')
  const [awayTeam, setAwayTeam] = useState('')
  const [matchId, setMatchId] = useState('')
  const [leagueId, setLeagueId] = useState('')
  const [status, setStatus] = useState('')
  const [sportsId, setSportsId] = useState('')
  const [homeScore, setHomeScore] = useState('')
  const [awayScore, setAwayScore] = useState('')
  const [matchDate, setMatchDate] = useState('')
  const [name, setName] = useState('')
  const [countryId, setCountryId] = useState('')
  const [isLive, setIsLive] = useState('')
  const [title, setTitle] = useState('')
  const [minute, setMinute] = useState('')
  const [country, setCountry] = useState('')
  const [half, setHalf] = useState('')
  const [matchHasEnded, setMatchHasEnded] = useState('')

  useEffect(() => {
    const data = props.data
    if (data) {
      dataIdRef.current = data._id

      setHomeTeam(data.homeTeam)
      setAwayTeam(data.awayTeam)
      setMatchId(data.matchId._id)
      setLeagueId(data.leagueId._id)
      setSportsId(data.sportsId)
      setHomeScore(data.homeScore)
      setAwayScore(data.awayScore)
      setMatchDate(data.matchDate.dateString)
      setName(data.name)
      setCountryId(data.countryId)
      setIsLive(data.isLive)
      setTitle(data.title)
      setMinute(data.minute)
      setCountry(data.country)
      setHalf(data.half)
      setMatchHasEnded(data.matchHasEnded)
      setStatus(data.status)

      setIsUpdate(true)
    }
  }, [props.data])

  async function createMatch(e) {
    setSubmitting(true)
    e.preventDefault()
    const gdFetchOption = {
      url: CREATE_MATCH,
      data: {
        homeTeam,
        awayTeam,
        matchId,
        leagueId,
        sportsId,
        homeScore,
        awayScore,
        matchDate,
        name,
        countryId,
        isLive,
        title,
        minute,
        country,
        half,
        matchHasEnded,
        status,
      },
    }
    let data
    try {
      data = await fetcher.fetch(gdFetchOption)
    } catch (er) {
      toast.error(er.message)
    }
    if (data) {
      if (!data.data.status) {
        toast.error(data.data.message)
      } else {
        props.setData && props.setData(data.data.generated)
        props.setReload && props.setReload()
        toast.success(data.data.message)
      }
    }
    setSubmitting(false)
  }

  async function updateMatch(e) {
    setSubmitting(true)
    e.preventDefault()
    const gdFetchOption = {
      url: CREATE_MATCH,
      method: 'PATCH',
      data: {
        id: dataIdRef.current,

        homeTeam,
        awayTeam,
        matchId,
        leagueId,
        sportsId,
        homeScore,
        awayScore,
        matchDate,
        name,
        countryId,
        isLive,
        title,
        minute,
        country,
        half,
        matchHasEnded,
        status,
      },
    }
    let data
    try {
      data = await fetcher.fetch(gdFetchOption)
    } catch (er) {
      toast.error(er.message)
    }
    if (data) {
      if (!data.data.status) {
        toast.error(data.data.message)
      } else {
        props.setData && props.setData(data.data.generated)
        props.setReload && props.setReload()
        toast.success(data.data.message)
      }
    }
    setSubmitting(false)
  }

  return (
    <Form onSubmit={(e) => (isUpdate ? updateMatch(e) : createMatch(e))}>
      <Row>
        <Col xs="12" sm="12" md="6" lg="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Home</InputGroup.Text>
            <Form.Control type="text" value={homeTeam} onChange={(e) => setHomeTeam(e.target.value)}></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" lg="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Away</InputGroup.Text>
            <Form.Control type="text" value={awayTeam} onChange={(e) => setAwayTeam(e.target.value)}></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" lg="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Match Id</InputGroup.Text>
            <Form.Control type="text" value={matchId} onChange={(e) => setMatchId(e.target.value)}></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" lg="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">league Id</InputGroup.Text>
            <Form.Control type="text" value={leagueId} onChange={(e) => setLeagueId(e.target.value)}></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" lg="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Sport Id</InputGroup.Text>
            <Form.Control type="text" value={sportsId} onChange={(e) => setSportsId(e.target.value)}></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" lg="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Home Score</InputGroup.Text>
            <Form.Control type="number" value={homeScore} onChange={(e) => setHomeScore(e.target.value)}></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" lg="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Away Score</InputGroup.Text>
            <Form.Control type="text" value={awayScore} onChange={(e) => setAwayScore(e.target.value)}></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" lg="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Match Date</InputGroup.Text>
            <Form.Control type="text" value={matchDate} onChange={(e) => setMatchDate(e.target.value)}></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" lg="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Name</InputGroup.Text>
            <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)}></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" lg="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Country Id</InputGroup.Text>
            <Form.Control type="text" value={countryId} onChange={(e) => setCountryId(e.target.value)}></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" lg="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Live</InputGroup.Text>
            <Form.Control type="text" value={isLive} onChange={(e) => setIsLive(e.target.value)}></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" lg="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Title</InputGroup.Text>
            <Form.Control type="text" value={title} onChange={(e) => setTitle(e.target.value)}></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" lg="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Minute</InputGroup.Text>
            <Form.Control type="number" value={minute} onChange={(e) => setMinute(e.target.value)}></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" lg="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Country</InputGroup.Text>
            <Form.Control type="text" value={country} onChange={(e) => setCountry(e.target.value)}></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" lg="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Half</InputGroup.Text>
            <Form.Control type="text" value={half} onChange={(e) => setHalf(e.target.value)}></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" lg="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Ended</InputGroup.Text>
            <Form.Control
              type="text"
              value={matchHasEnded}
              onChange={(e) => setMatchHasEnded(e.target.value)}
            ></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" lg="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">
              Status &nbsp;&nbsp;
              <Form.Switch checked={status} onChange={() => setStatus(status ? 0 : 1)}></Form.Switch>
            </InputGroup.Text>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" lg="6" className="p-1">
          <Spinner loading={submitting} loadingText={`${isUpdate ? 'Updating match' : 'Creating match'}`}>
            <Form.Control
              size="md"
              type="submit"
              value={`${isUpdate ? 'Update' : 'Create'}`}
              className="fw-bold utilityLink"
            ></Form.Control>
          </Spinner>
        </Col>
      </Row>
    </Form>
  )
}
export default MatchForm
