/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react'
import { Col, Form, InputGroup, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { CREATE_PACKAGE } from '../../../../scripts/config/RestEndpoints'
import Spinner from '../../../paginating/Spinner'
import fetcher from '../../../../scripts/SharedFetcher'

function PackageForm(props) {
  const dataIdRef = useRef('')

  const [isUpdate, setIsUpdate] = useState(false)

  const [submitting, setSubmitting] = useState(false)

  const [name, setName] = useState('')
  const [amount, setAmount] = useState('')
  const [code, setCode] = useState('')
  const [dailyPercentageLimit, setDailyPercentageLimit] = useState('')
  const [renewalDurationInDays, setRenewalDurationInDays] = useState('')
  const [description, setDescription] = useState('')
  const [energyRefillingInDays, setEnergyRefillingInDays] = useState('')
  const [totalTradesLimit, setTotalTradesLimit] = useState('')
  const [minAmountPerTrade, setMinAmountPerTrade] = useState('')
  const [maxAmountPerTrade, setMaxAmountPerTrade] = useState('')

  useEffect(() => {
    const data = props.data
    if (data) {
      dataIdRef.current = data._id

      setName(data.name)
      setAmount(data.amount)
      setCode(data.code)
      setDailyPercentageLimit(data.dailyPercentageLimit)
      setRenewalDurationInDays(data.renewalDurationInDays)
      setEnergyRefillingInDays(data.energyRefillingInDays)
      setDescription(data.description)
      setTotalTradesLimit(data.totalTradesLimit)
      setMinAmountPerTrade(data.minAmountPerTrade)
      setMaxAmountPerTrade(data.maxAmountPerTrade)

      setIsUpdate(true)
    }
  }, [props.data])

  async function createPackage(e) {
    setSubmitting(true)
    e.preventDefault()
    const gdFetchOption = {
      url: CREATE_PACKAGE,
      data: {
        name: name?.trim(),
        amount,
        code,
        dailyPercentageLimit,
        renewalDurationInDays,
        description,
        energyRefillingInDays,
        totalTradesLimit,
        minAmountPerTrade,
        maxAmountPerTrade,
      },
    }
    let data
    try {
      data = await fetcher.fetch(gdFetchOption)
    } catch (er) {
      toast.error(er.message)
    }
    if (data) {
      if (!data.data.status) {
        toast.error(data.data.message)
      } else {
        props.setData && props.setData(data.data.generated)
        props.setReload && props.setReload()
        toast.success(data.data.message)
      }
    }
    setSubmitting(false)
  }

  async function updatePackage(e) {
    setSubmitting(true)
    e.preventDefault()
    const gdFetchOption = {
      url: CREATE_PACKAGE,
      method: 'PATCH',
      data: {
        id: dataIdRef.current,
        name: name?.trim(),
        amount,
        code,
        dailyPercentageLimit,
        renewalDurationInDays,
        description,
        energyRefillingInDays,
        totalTradesLimit,
        minAmountPerTrade,
        maxAmountPerTrade,
      },
    }
    let data
    try {
      data = await fetcher.fetch(gdFetchOption)
    } catch (er) {
      toast.error(er.message)
    }
    if (data) {
      if (!data.data.status) {
        toast.error(data.data.message)
      } else {
        props.setData && props.setData(data.data.generated)
        props.setReload && props.setReload()
        toast.success(data.data.message)
      }
    }
    setSubmitting(false)
  }

  return (
    <Form onSubmit={(e) => (isUpdate ? updatePackage(e) : createPackage(e))}>
      <Row>
        <Col xs="12" sm="12" md="6" lg="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Name</InputGroup.Text>
            <Form.Control
              required={true}
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" lg="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">
              Amount (<i className="fas fa-dollar-sign text-danger"></i>)
            </InputGroup.Text>
            <Form.Control
              required={true}
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            ></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" lg="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Level</InputGroup.Text>
            <Form.Control
              required={true}
              type="number"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            ></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" lg="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Daily Percent</InputGroup.Text>
            <Form.Control
              required={true}
              type="number"
              title="Daily Percentage Limit for this Package"
              value={dailyPercentageLimit}
              onChange={(e) => setDailyPercentageLimit(e.target.value)}
            ></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" lg="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Renewal</InputGroup.Text>
            <Form.Control
              required={true}
              type="number"
              title="Renewal duration in days"
              value={renewalDurationInDays}
              onChange={(e) => setRenewalDurationInDays(e.target.value)}
            ></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" lg="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Energy</InputGroup.Text>
            <Form.Control
              required={true}
              type="number"
              title="Energy Refilling in days"
              value={energyRefillingInDays}
              onChange={(e) => setEnergyRefillingInDays(e.target.value)}
            ></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" lg="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Max Trades</InputGroup.Text>
            <Form.Control
              required={true}
              type="number"
              title="Total number of trades per a plan of a package"
              value={totalTradesLimit}
              onChange={(e) => setTotalTradesLimit(e.target.value)}
            ></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" lg="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Min Amount</InputGroup.Text>
            <Form.Control
              required={true}
              type="number"
              title="Minimum amount of trade"
              value={minAmountPerTrade}
              onChange={(e) => setMinAmountPerTrade(e.target.value)}
            ></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" lg="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Max Amount</InputGroup.Text>
            <Form.Control
              required={true}
              type="number"
              title="Maximum amount of trade"
              value={maxAmountPerTrade}
              onChange={(e) => setMaxAmountPerTrade(e.target.value)}
            ></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" className="p-1">
          <label className="fw-bold">Description</label>
          <textarea
            className="form-control"
            style={{ width: '100%' }}
            required={true}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          >
            {description}
          </textarea>
        </Col>

        <Col xs="12" sm="12" md="6" lg="6" className="p-1">
          <Spinner loading={submitting} loadingText={`${isUpdate ? 'Updating package' : 'Creating package'}`}>
            <Form.Control
              size="md"
              type="submit"
              value={`${isUpdate ? 'Update' : 'Create'}`}
              className="fw-bold utilityLink"
            ></Form.Control>
          </Spinner>
        </Col>
      </Row>
    </Form>
  )
}
export default PackageForm
