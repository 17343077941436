/* eslint-disable no-undef */
import React from 'react'
import Main from '../layout/Main'
import { Card, Col, Container, Nav, NavItem, Row } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import TokenTab from '../components/tabs/token/TokenTab'
import TransactionTokenTab from '../components/tabs/token/TransactionTokenTab'

function Token() {
  const location = useLocation()
  const tab = new URLSearchParams(location.search)?.get('tab')

  return (
    <>
      <Main>
        <Container fluid>
          <Row>
            <Col xs="12" sm="12" md="4" lg="3">
              <Card>
                <Card.Header className="mb-0 pb-0">
                  <h5>Token</h5>
                </Card.Header>
                <Card.Header className="mt-0 pt-0">Token and Transaction Token</Card.Header>
                <Card.Footer className="mt-0 pt-0 mx-1 px-1">
                  <Nav variant="pills" className="s-grid">
                    <NavItem>
                      <Link to="../token" className={`nav-link ${!tab || tab === 'token' ? 'active' : ''}`}>
                        <i className="fas fa-bitcoin-sign me-1"></i> Tokens
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link
                        to="../token?tab=transaction"
                        className={`nav-link ${tab === 'transaction' ? 'active' : ''}`}
                      >
                        <i className="fas fa-dollar-sign me-1"></i> Transaction Tokens
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link to="../token?tab=coins" className={`nav-link ${tab === 'coins' ? 'active' : ''}`}>
                        <i className="fas fa-dollar-sign me-1"></i> Coins
                      </Link>
                    </NavItem>
                  </Nav>
                </Card.Footer>
              </Card>
            </Col>
            <Col xs="12" sm="12" md="8" lg="9" className="mt-3">
              <Card>
                <Card.Body>{tab == 'transaction' ? <TransactionTokenTab /> : <TokenTab />}</Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Main>
    </>
  )
}
export default Token
