import Betting from '../../pages/Betting'
import Dashboard from '../../pages/Dashboard'
import Git from '../../pages/Git'
import Migration from '../../pages/Migration'
import Network from '../../pages/Network'
import Option from '../../pages/Option'
import Package from '../../pages/Package'
import ReferralLevel from '../../pages/ReferralLevel'
import Refresh from '../../pages/Refresh'
import Setting from '../../pages/Setting'
import SystemRevenue from '../../pages/SystemRevenue'
import Token from '../../pages/Token'
import Transaction from '../../pages/Transaction'
import User from '../../pages/User'

const routes = {
  insecure: [],
  secure: [
    {
      path: '/home',
      component: Dashboard,
    },
    {
      path: '/user',
      component: User,
    },
    {
      path: '/network',
      component: Network,
    },
    {
      path: '/token',
      component: Token,
    },
    {
      path: '/package',
      component: Package,
    },
    {
      path: '/transaction',
      component: Transaction,
    },
    {
      path: '/referral-level',
      component: ReferralLevel,
    },
    {
      path: '/option',
      component: Option,
    },
    {
      path: '/setting',
      component: Setting,
    },
    {
      path: '/refresh',
      component: Refresh,
    },
    {
      path: '/system-revenue',
      component: SystemRevenue,
    },
    {
      path: '/git',
      component: Git,
    },
    {
      path: '/betting',
      component: Betting,
    },
    {
      path: '/migration',
      component: Migration,
    },
  ],
}

export default routes
