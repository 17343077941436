/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react'
import { Col, Form, InputGroup, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { CREATE_SYSTEM_REVENUE } from '../../../../scripts/config/RestEndpoints'
import Spinner from '../../../general/Spinner'
import fetcher from '../../../../scripts/SharedFetcher'
import { WITHDRAW } from '../../../../scripts/config/contants'

function SystemRevenueForm(props) {
  const dataIdRef = useRef('')

  const [isUpdate, setIsUpdate] = useState(false)

  const [submitting, setSubmitting] = useState(false)

  const [admin, setAdmin] = useState('')
  const [address, setAddress] = useState('')
  const [type, setType] = useState('')
  const [reference, setReference] = useState('')
  const [amount, setAmount] = useState('')
  const [description, setDescription] = useState('')

  useEffect(() => {
    const data = props.data
    if (data) {
      dataIdRef.current = data._id

      setAdmin(data.admin || '')
      setAddress(data.address || '')
      setType(data.type || '')
      setReference(data.reference || '')
      setAmount(data.amount || '')
      setDescription(data.description || '')

      setIsUpdate(true)
    }
  }, [props.data])

  async function createSystemRevenue(e) {
    setSubmitting(true)
    e.preventDefault()
    const gdFetchOption = {
      url: CREATE_SYSTEM_REVENUE,
      data: {
        admin,
        address,
        type,
        reference,
        amount,
        description,
      },
    }
    let data
    try {
      data = await fetcher.fetch(gdFetchOption)
    } catch (er) {
      toast.error(er.message)
    }
    if (data) {
      if (!data.data.status) {
        toast.error(data.data.message)
      } else {
        props.setData && props.setData(data.data.generated)
        props.setReload && props.setReload()
        toast.success(data.data.message)
      }
    }
    setSubmitting(false)
  }

  async function updateSystemRevenue(e) {
    setSubmitting(true)
    e.preventDefault()
    const gdFetchOption = {
      url: CREATE_SYSTEM_REVENUE,
      method: 'PATCH',
      data: {
        id: dataIdRef.current,
        admin,
        address,
        type,
        reference,
        amount,
        description,
      },
    }
    let data
    try {
      data = await fetcher.fetch(gdFetchOption)
    } catch (er) {
      toast.error(er.message)
    }
    if (data) {
      if (!data.data.status) {
        toast.error(data.data.message)
      } else {
        props.setData && props.setData(data.data.generated)
        props.setReload && props.setReload()
        toast.success(data.data.message)
      }
    }
    setSubmitting(false)
  }

  return (
    <Form onSubmit={(e) => (isUpdate ? updateSystemRevenue(e) : createSystemRevenue(e))}>
      <Row>
        <Col xs="12" sm="12" md="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Admin UID</InputGroup.Text>
            <Form.Control type="text" value={admin} onChange={(e) => setAdmin(e.target.value)}></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Wallet Address</InputGroup.Text>
            <Form.Control type="text" value={address} onChange={(e) => setAddress(e.target.value)}></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Type</InputGroup.Text>
            <Form.Select value={type} onChange={(e) => setType(e.target.value)}>
              <option value={''}>Other</option>
              <option value={WITHDRAW}>{WITHDRAW}</option>
            </Form.Select>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Reference</InputGroup.Text>
            <Form.Control type="text" value={reference} onChange={(e) => setReference(e.target.value)}></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Amount</InputGroup.Text>
            <Form.Control type="number" value={amount} onChange={(e) => setAmount(e.target.value)}></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" className="p-1">
          <InputGroup>
            <label className="fw-bold">Description</label>
            <br />
            <textarea
              className="form-control s-100w-p"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            >
              {description}
            </textarea>
          </InputGroup>
        </Col>

        <Col xs="12" sm="12" md="6" className="p-1">
          <Spinner
            loading={submitting}
            loadingText={`${isUpdate ? 'Updating system revenue' : 'Creating system revenue'}`}
          >
            <Form.Control
              size="md"
              type="submit"
              value={`${isUpdate ? 'Update' : 'Create'}`}
              className="fw-bold utilityLink"
            ></Form.Control>
          </Spinner>
        </Col>
      </Row>
    </Form>
  )
}
export default SystemRevenueForm
