/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
import { Col, Form, InputGroup, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { CREATE_USER } from '../../../../scripts/config/RestEndpoints'
import Spinner from '../../../general/Spinner'
import fetcher from '../../../../scripts/SharedFetcher'
import { ADMIN, USER } from '../../../../scripts/config/contants'

function UserForm(props) {
  const dataIdRef = useRef('')

  const [isUpdate, setIsUpdate] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [role, setRole] = useState(1)
  const [status, setStatus] = useState(0)

  const [refID, setRefID] = useState('')
  const [yesterdayNumberOfTrade, setYesterdayNumberOfTrade] = useState('')
  const [yesterdayProfit, setYesterdayProfit] = useState('')
  const [yesterdayTradePercentage, setYesterdayTradePercentage] = useState('')
  const [todayNumberOfTrade, setTodayNumberOfTrade] = useState('')
  const [todayProfit, setTodayProfit] = useState('')
  const [todayTradePercentage, setTodayTradePercentage] = useState('')
  const [currentMonthProfit, setCurrentMonthProfit] = useState('')
  const [currentMonthNumberOfTrade, setCurrentMonthNumberOfTrade] = useState('')
  const [currentMonthTradePercentage, setCurrentMonthTradePercentage] = useState('')
  const [lastMonthProfit, setLastMonthProfit] = useState('')
  const [lastMonthNumberOfTrade, setLastMonthNumberOfTrade] = useState('')
  const [lastMonthTradePercentage, setLastMonthTradePercentage] = useState('')
  const [energy, setEnergy] = useState('')

  useEffect(() => {
    const data = props.data
    if (data) {
      dataIdRef.current = data._id
      setFirstname(data.firstname)
      setLastname(data.lastname)
      setEmail(data.email)
      setRole(data.role)
      setRefID(data.refID)
      setYesterdayNumberOfTrade(data.yesterdayNumberOfTrade)
      setYesterdayProfit(data.yesterdayProfit)
      setYesterdayTradePercentage(data.yesterdayTradePercentage)
      setTodayNumberOfTrade(data.todayNumberOfTrade)
      setTodayProfit(data.todayProfit)
      setTodayTradePercentage(data.todayTradePercentage)
      setCurrentMonthProfit(data.currentMonthProfit)
      setCurrentMonthNumberOfTrade(data.currentMonthNumberOfTrade)
      setCurrentMonthTradePercentage(data.currentMonthTradePercentage)
      setLastMonthProfit(data.lastMonthProfit)
      setLastMonthNumberOfTrade(data.lastMonthNumberOfTrade)
      setLastMonthTradePercentage(data.lastMonthTradePercentage)
      setEnergy(data.energy)
      setStatus(data.status)
      setIsUpdate(true)
    }
  }, [props.data])

  async function createUser(e) {
    setSubmitting(true)
    e.preventDefault()

    const gdFetchOption = {
      url: CREATE_USER,
      data: {
        firstname,
        lastname,
        email,
        yesterdayNumberOfTrade,
        yesterdayProfit,
        yesterdayTradePercentage,
        todayNumberOfTrade,
        todayProfit,
        todayTradePercentage,
        currentMonthProfit,
        currentMonthNumberOfTrade,
        currentMonthTradePercentage,
        lastMonthProfit,
        lastMonthNumberOfTrade,
        lastMonthTradePercentage,
        energy,
        role,
        refID,
        status,
      },
    }
    let data
    try {
      data = await fetcher.fetch(gdFetchOption)
    } catch (er) {
      toast.error(er.message)
    }
    if (data) {
      if (!data.data.status) {
        toast.error(data.data.message)
      } else {
        props.setData && props.setData(data.data.generated)
        props.setReload && props.setReload()
        toast.success(data.data.message)
      }
    }
    setSubmitting(false)
  }

  async function updateUser(e) {
    setSubmitting(true)
    e.preventDefault()
    const gdFetchOption = {
      url: CREATE_USER,
      method: 'PATCH',
      data: {
        uid: dataIdRef.current,
        firstname,
        lastname,
        email,
        refID,
        yesterdayNumberOfTrade,
        yesterdayProfit,
        yesterdayTradePercentage,
        todayNumberOfTrade,
        todayProfit,
        todayTradePercentage,
        currentMonthProfit,
        currentMonthNumberOfTrade,
        currentMonthTradePercentage,
        lastMonthProfit,
        lastMonthNumberOfTrade,
        lastMonthTradePercentage,
        energy,
        role,
        status,
      },
    }
    let data
    try {
      data = await fetcher.fetch(gdFetchOption)
    } catch (er) {
      toast.error(er.message)
    }
    if (data) {
      if (!data.data.status) {
        toast.error(data.data.message)
      } else {
        props.setData && props.setData(data.data.generated)
        props.setReload && props.setReload()
        toast.success(data.data.message)
      }
    }
    setSubmitting(false)
  }

  return (
    <Form onSubmit={(e) => (isUpdate ? updateUser(e) : createUser(e))}>
      <Row>
        <Col xs="12" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Firstname</InputGroup.Text>
            <Form.Control
              required={true}
              type="text"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value?.trim())}
            ></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Lastname</InputGroup.Text>
            <Form.Control
              required={true}
              type="text"
              value={lastname}
              onChange={(e) => setLastname(e.target.value?.trim())}
            ></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Email </InputGroup.Text>
            <Form.Control
              required={true}
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value?.trim()?.toLowerCase())}
            ></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Ref UID</InputGroup.Text>
            <Form.Control type="number" value={refID} onChange={(e) => setRefID(e.target.value)}></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Yesterday Trades</InputGroup.Text>
            <Form.Control
              type="number"
              value={yesterdayNumberOfTrade}
              onChange={(e) => setYesterdayNumberOfTrade(e.target.value)}
            ></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Yesterday Profit</InputGroup.Text>
            <Form.Control
              type="number"
              value={yesterdayProfit}
              onChange={(e) => setYesterdayProfit(e.target.value)}
            ></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Yesterday Trade Percent</InputGroup.Text>
            <Form.Control
              type="number"
              value={yesterdayTradePercentage}
              onChange={(e) => setYesterdayTradePercentage(e.target.value)}
            ></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Today Trades</InputGroup.Text>
            <Form.Control
              type="number"
              value={todayNumberOfTrade}
              onChange={(e) => setTodayNumberOfTrade(e.target.value)}
            ></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Today Profit</InputGroup.Text>
            <Form.Control
              type="number"
              value={todayProfit}
              onChange={(e) => setTodayProfit(e.target.value)}
            ></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Today Trade Percent</InputGroup.Text>
            <Form.Control
              type="number"
              value={todayTradePercentage}
              onChange={(e) => setTodayTradePercentage(e.target.value)}
            ></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">This Month Profit</InputGroup.Text>
            <Form.Control
              type="number"
              value={currentMonthProfit}
              onChange={(e) => setCurrentMonthProfit(e.target.value)}
            ></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">This Month Trades</InputGroup.Text>
            <Form.Control
              type="number"
              value={currentMonthNumberOfTrade}
              onChange={(e) => setCurrentMonthNumberOfTrade(e.target.value)}
            ></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">This Month Trade Percent</InputGroup.Text>
            <Form.Control
              type="number"
              value={currentMonthTradePercentage}
              onChange={(e) => setCurrentMonthTradePercentage(e.target.value)}
            ></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Last Month Profit</InputGroup.Text>
            <Form.Control
              type="number"
              value={lastMonthProfit}
              onChange={(e) => setLastMonthProfit(e.target.value)}
            ></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Last Month Trades</InputGroup.Text>
            <Form.Control
              type="number"
              value={lastMonthNumberOfTrade}
              onChange={(e) => setLastMonthNumberOfTrade(e.target.value)}
            ></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Last Month Trade Percent</InputGroup.Text>
            <Form.Control
              type="number"
              value={lastMonthTradePercentage}
              onChange={(e) => setLastMonthTradePercentage(e.target.value)}
            ></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Energy</InputGroup.Text>
            <Form.Control type="number" value={energy} onChange={(e) => setEnergy(e.target.value)}></Form.Control>
          </InputGroup>
        </Col>

        <Col xs="12" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">Role</InputGroup.Text>
            <Form.Select
              required={true}
              value={role}
              onChange={(e) => setRole(e.target.value === ADMIN ? ADMIN : USER)}
            >
              <option key="first" value="">
                Select Role
              </option>
              <option key="user" value={USER}>
                User
              </option>
              <option key="admin" value={ADMIN}>
                Admin
              </option>
            </Form.Select>
          </InputGroup>
        </Col>

        <Col xs="12" lg="6" className="p-1">
          <InputGroup>
            <InputGroup.Text className="fw-bold">
              Status &nbsp;&nbsp;
              <Form.Switch checked={status} onChange={(e) => setStatus(status ? 0 : 1)}></Form.Switch>
            </InputGroup.Text>
          </InputGroup>
        </Col>

        <Col xs="12" className="p-1">
          <Spinner loading={submitting} loadingText={`${isUpdate ? 'Updating user' : 'Creating user'}`}>
            <Form.Control
              size="md"
              type="submit"
              value={`${isUpdate ? 'Update' : 'Create'}`}
              className="fw-bold utilityLink"
            ></Form.Control>
          </Spinner>
        </Col>
      </Row>
    </Form>
  )
}
export default UserForm
