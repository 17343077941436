import React, { useRef, useState } from 'react'
import { ALL_BETSLIP, BETSLIP } from '../../../scripts/config/RestEndpoints'
import PaginatedTable, { DESCENDING } from '../../paginating/PaginatedTable'
import { FaTrash } from 'react-icons/fa'
import ModalBox from '../../general/Modal'
import { Button, ButtonGroup } from 'react-bootstrap'
import { toast } from 'react-toastify'
import fetcher from '../../../scripts/SharedFetcher'
import accounting from 'accounting'
import BetSlipForm from './betslip/BetSlipForm'

function BetSlip() {
  const [reload, setReload] = useState(false)
  const [showCreateForm, setShowCreateForm] = useState(false)
  const [itemId, setItemId] = useState('')
  const [showConfirmDeletion, setShowConfirmDeletion] = useState(false)
  const [updatingData, setUpdatingData] = useState(null)
  const [activeBetItems, setActiveBetItems] = useState([])
  const [showBetItems, setShowBetItems] = useState(false)

  const urlRef = useRef(ALL_BETSLIP)

  const fieldsRef = useRef({
    _id: { name: 'ID', type: String },
    uid: {
      name: 'User',
      type: Number,
      transform: {
        out: (row) => (
          <>
            <div className="text-italic">{row?.uid?._id}</div>
            <div className="fw-bold">{row?.uid?.firstname + ' ' + row?.uid?.lastname}</div>
          </>
        ),
      },
    },
    amountStaked: { name: 'Stake', type: Number },
    totalOdds: { name: 'Total odds', type: Number },
    potentialWinning: {
      name: 'Stake',
      type: Number,
      transform: {
        out: (row) => (
          <>
            <div className="fw-bold">{accounting.formatMoney(row?.potentialWinning, '$', 4)}</div>
          </>
        ),
      },
    },
    betItems: {
      name: 'Bet odds',
      type: String,
      transform: {
        out: (row) => (
          <>
            <Button
              onClick={() => {
                setActiveBetItems(row.betItems)
                setShowBetItems(true)
              }}
              style={{ padding: '5px' }}
              title="Show bet items"
              variant="warning"
            >
              Odds
            </Button>
          </>
        ),
      },
    },
    win: { name: 'Win', type: Boolean },
    lose: { name: 'Lose', type: Boolean },
    status: { name: 'Status', type: String },
    'createdAt.date': { name: 'Created', type: Date },
    'updatedAt.date': { name: 'Updated', type: Date, hideFromSearch: true },
    action: {
      name: () => (
        <Button
          onClick={() => {
            setShowCreateForm(true)
          }}
          style={{ padding: '5px' }}
          title="Create Betslip"
          variant="warning"
        >
          <i className="fas fa-user"></i> Create
        </Button>
      ),
      type: String,
      virtual: true,
      transform: { out },
    },
  })

  const queryRef = useRef({
    populate: ['betItems'],
  })

  async function deleteBetSlip(betslipId) {
    const fetchData = {
      url: BETSLIP + betslipId,
      method: 'DELETE',
    }
    let data = null
    try {
      data = await fetcher.fetch(fetchData)
    } catch (er) {
      toast.error(er.message)
    }
    if (!data?.data?.status) {
      toast.error(data?.data?.message || 'Error')
    } else {
      setShowConfirmDeletion(false)
      setReload(!reload)
      toast.success(data?.data?.message || 'Success')
    }
  }

  function out(rowData) {
    return (
      <ButtonGroup size="sm">
        <Button
          onClick={() => {
            setShowConfirmDeletion(true)
            setItemId(rowData._id)
          }}
          style={{ padding: '5px' }}
          title="Delete this betslip"
          variant="danger"
        >
          <FaTrash />
        </Button>
        <Button
          onClick={() => {
            setShowCreateForm(true)
            setUpdatingData(rowData)
          }}
          style={{ padding: '5px' }}
          title="Edit this betslip"
          variant="warning"
        >
          <i className="fas fa-edit"></i>
        </Button>
      </ButtonGroup>
    )
  }
  return (
    <>
      <ModalBox
        show={showBetItems}
        onCancel={() => setShowBetItems(false)}
        onAccept={() => setShowBetItems(false)}
        header={<h2 className="text-center">Bet Slip Odds</h2>}
        type="success"
        backdrop
      >
        <ul>
          {activeBetItems.map((betItem) => (
            <>
              <li>{betItem.value}</li>
            </>
          ))}
        </ul>
      </ModalBox>

      <ModalBox
        show={showConfirmDeletion}
        onCancel={() => setShowConfirmDeletion(false)}
        onAccept={() => deleteBetSlip(itemId)}
        header={<h2 className="text-center">Confirm Deletion</h2>}
        type="danger"
        backdrop
      >
        <span>Are Sure you want to delete this betslip</span>
      </ModalBox>

      <ModalBox
        show={showCreateForm}
        onCancel={() => {
          setShowCreateForm(false)
          setUpdatingData(null)
        }}
        control={false}
        header={<h2 className="text-center">{`${updatingData ? 'Update' : 'Create'}`} BetSlip</h2>}
        backdrop
      >
        {!updatingData ? <BetSlipForm /> : <BetSlipForm setReload={() => setReload(!reload)} data={updatingData} />}
      </ModalBox>

      <PaginatedTable
        url={urlRef.current}
        dataName="betslips"
        fields={fieldsRef.current}
        query={queryRef.current}
        primaryKey="createdAt.date"
        sortOrder={DESCENDING}
        forCurrentUser={false}
        reload={reload}
      />
    </>
  )
}

export default BetSlip
